<template>
  <svg
    :class="{ open: !close }"
    class="icon icon--with-margin icon--plus-plus"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      stroke="currentColor"
      stroke-width="1.3"
      y1="6"
      y2="18"
      x1="12"
      x2="12"
    ></line>
    <line
      stroke="currentColor"
      stroke-width="1.3"
      x1="6"
      x2="18"
      y1="12"
      y2="12"
    ></line>
  </svg>
</template>

<script>
export default {
  props: {
    close: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
.icon
  line
    transform-origin: center
    transition: transform 0.5s ease

  &.open
    :nth-child(1)
      transform: rotate(270deg)

    :nth-child(2)
      transform: rotate(180deg)
</style>