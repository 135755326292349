<template>
  <div class="info-accordion">
    <div class="info-accordion-header" @click="$emit('click', $event)">
      <div class="title">{{ title }}</div>
      <open-icon :close="!open"></open-icon>
    </div>
    <div
      class="info-accordion-body"
      :class="{ close: !open }"
      v-html="description"
    ></div>
  </div>
</template>

<script>
import OpenIcon from "./OpenIcon.vue";
export default {
  components: { OpenIcon },
  props: {
    title: String,
    description: String,
    open: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
.info-accordion
  display: flex
  flex-direction: column
  font-weight: 400
  min-height: vw(60px)

  .mobile &
    min-height: vw(50px)

  &:last-child
    margin-bottom: vw(15px)

  .info-accordion-header
    align-items: center
    cursor: pointer
    display: flex
    flex: 0 0 vw(60px)

    .mobile &
      flex: 0 0 vw(50px)

    &:hover
      .icon
        transform: translateY(-20%)

    .title
      m-font("Paco")
      m-font-size(12, 14)
      flex: 1 1 100%
      letter-spacing: 0.05em
      text-transform: uppercase

    .icon
      transition: transform 0.3s ease-in-out
      width: vw(30px)

      .mobile &
        width: vw(20px)

  .info-accordion-body
    m-font-size(14, 20)
    overflow: hidden
    padding-bottom: vw(15px)

    &.close
      max-height: 0
      padding-bottom: 0
</style>