<template>
  <div class="recommendation-one-perfume wizard-v2" ref="mainRef">
    <div v-if="loading" class="loading">
      <div
        class="loading-icon"
        :class="{ man: isMan, woman: isWoman, pacollection: isPacollection }"
      ></div>
      <div class="loading-title">{{ $t("please_wait") }}</div>
      <div class="loading-sub-title">{{ $t("we_are_looking") }}</div>
    </div>
    <transition name="fade">
      <smelling-view
        v-if="selectedEan"
        class="smelling-component"
        :ean="selectedEan"
        :color="selectedPerfume ? selectedPerfume.backgroundColor : '#fff'"
        :duration="7000"
        @close="handleClose"
      />
    </transition>
    <div v-if="perfume" class="container">
      <div
        class="wrapper-header"
        :style="{
          backgroundImage: `url(${backgroundData.backgroundIngredientImageUrl})`,
        }"
      >
        <div class="header">
          <!-- <BackIcon /> -->
          <div class="header-title">{{ $t("result_you_may_like") }}</div>
          <div class="header-restart" @click="goBack">{{ $t("restart") }}</div>
        </div>
        <div
          class="body"
          :class="lightOrDark(perfume.backgroundColor)"
          :style="{
            backgroundColor: perfume.backgroundColor,
            color: pickTextColorBasedOnBgColorSimple(perfume.backgroundColor),
          }"
        >
          <div class="left">
            <div
              class="image"
              :style="'background-image: url(' + perfume.imageUrl + ')'"
            >
              <!-- <img :src="perfume.imageUrl" :alt="perfume.name" /> -->
            </div>
          </div>
          <div class="right">
            <div class="header-right">
              <div class="title">{{ perfume.title }}</div>
              <div class="subtitle">{{ perfume.typeOfPerfume }}</div>
            </div>
            <accordion
              :description="perfume.description"
              :title="$t('perfume_description')"
              :open="accordionOpended === 0"
              @click="() => handleClickAcordion(0)"
            />
            <hr />
            <accordion
              :description="perfumeIngredients"
              :title="$t('perfume_flavors')"
              :open="accordionOpended === 1"
              @click="() => handleClickAcordion(1)"
            />
            <hr />
            <intensities-table
              :end="true"
              :perfume="perfume"
              :color="
                pickTextColorBasedOnBgColorSimple(perfume.backgroundColor)
              "
            ></intensities-table>
            <div
              class="btn btn-discover"
              @click="() => handleClickSmell(perfume)"
              :class="{ disabled: disabled(perfume) }"
            >
              {{ $t("result_cta_discover") }}
            </div>
          </div>
        </div>
        <div class="restart" @click="goBack" v-if="isMobile">
          <div class="restart-text">{{ $t("restart") }}</div>
        </div>
      </div>
      <div class="other-perfumes">
        <div class="title">{{ $t("result_you_may_like") }}</div>
        <div v-if="!isMobile" class="wrapper">
          <perfume-card
            v-for="perfume in otherPerfumes"
            :perfume="perfume"
            :key="perfume.id"
            @click="() => handleClickSmell(perfume)"
          />
        </div>
        <swiper
          v-else
          class="swiper-carousel carousel btn-wrapper"
          ref="mySwiper"
          :options="swiperOptions"
        >
          <swiper-slide
            class="swiper-slide slide"
            v-for="(perfume, index) in otherPerfumes"
            :key="index"
          >
            <perfume-card
              :perfume="perfume"
              :key="perfume.id"
              @click="() => handleClickSmell(perfume)"
            />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "../components/Accordion.vue";
import IntensitiesTable from "../components/IntensitiesTable.vue";
import getSalepointMixin from "../mixins/getSalepointMixin";
import queryStringMixin from "../mixins/queryStringMixin";
import { Api } from "../services";
import PerfumeCard from "../components/PerfumeCard.vue";
import { mapActions } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import { WIZARD_V2 } from "../router";
import SmellingView from "../components/SmellingView.vue";
import { pickTextColorBasedOnBgColorSimple } from "../utils/colorUtils";

export default {
  directives: {
    swiper: directive,
  },

  components: {
    Accordion,
    IntensitiesTable,
    PerfumeCard,
    Swiper,
    SwiperSlide,
    SmellingView,
  },
  mixins: [queryStringMixin, getSalepointMixin],
  data() {
    return {
      selectedEan: null,
      selectedPerfume: null,
      accordionOpended: null,
      swiperOptions: {
        slidesPerView: "auto",
        // spaceBetween: 3,
        //spaceBetween: 150,
        centeredSlides: false,
        pagination: {
          type: "progressbar",
          el: ".swiper-pagination",
        },
      },
    };
  },
  mounted() {
    this.eventTracker.emitEvent("VIEW_OPENED", { type: "VIEW_RECOS" });
    setTimeout(() => {
      this.waiting = false;
    }, this.minimalDelay);
  },
  fetch: {
    backoffice: {
      model() {
        return isNaN(this.$route.params.catalogue) ? "profiler" : "salepoint";
      },
      method: "getById",
      params() {
        return this.$route.params.catalogue
          ? [this.$route.params.catalogue]
          : null;
      },
    },
  },
  asyncComputed: {
    recommendation: {
      get() {
        if (this.backoffice)
          return Api.getSimilarByIngredients(
            this.lang,
            this.intensities,
            this.concepts,
            this.gender,
            this.intensity / 100,
            this.pacollection,
            this.id,
            this.families
          ).then((reco) => {
            const resultingProfile = {
              intensity: parseInt(this.intensity),
              families: this.families,
            };
            const intensities = this.intensities.map((i) => ({
              id: i.ingredientId,
              proportion: i.proportion,
            }));
            if (this.isConcept) {
              resultingProfile.concepts = intensities;
            } else {
              resultingProfile.ingredients = intensities;
            }
            this.eventTracker.emitEvent("RECOMMENDATION_FETCH", {
              resultingProfile,
              filter: { gender: this.gender },
              topResults: reco.map(({ id }) => id),
            });
            return reco
              .filter((e) => !this.disabled(e))
              .map((e) => ({
                ...e,
                subTitle: e.meFamilyName,
                image1: e.productImageLink,
                image2: e.resultPageImageLink,
                descriptionTitle: e.clientFamilyName,
              }));
          });
      },
      default: [],
    },
    catalog: {
      get() {
        return Api.getCatalog(this.gender);
      },
      default: [],
    },
  },
  computed: {
    selected() {
      return Number(this.$route.query.selected) || 0;
    },
    lang() {
      return this.$route.params.locale;
    },
    perfumeIngredients() {
      return this.perfume.ingredientProportions
        .map((e) => e.ingredient.name)
        .join(", ");
    },
    backgroundData() {
      return (
        this.catalog?.find((cat) =>
          cat.perfumesIds.some((id) => id === this.perfume?.id)
        ) ?? {}
      );
    },
    loading() {
      return this.$asyncComputed.recommendation.updating;
    },
    perfume() {
      if (!this.recommendation) return null;
      if (this.selected)
        return (
          this.recommendation.find((perfume) => perfume.id === this.selected) ||
          this.recommendation[0]
        );
      else return this.recommendation[0];
    },
    otherPerfumes() {
      return this.recommendation?.filter((e) => e.id !== this.perfume.id);
    },
    intensities() {
      return this.ingredients.map((key) => ({
        ingredientId: key,
        proportion: 10,
      }));
    },
  },
  methods: {
    pickTextColorBasedOnBgColorSimple,
    ...mapActions(["startTimer"]),
    handleClose() {
      this.selectedEan = null;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    },
    goBack() {
      this.$router.push({ name: WIZARD_V2 });
    },
    handleClickAcordion(index) {
      if (index === this.accordionOpended) this.accordionOpended = null;
      else this.accordionOpended = index;
    },
    disabled(perfume) {
      // return false
      const url = perfume.productUrl || perfume.productLink;
      return this.isProfiler && !this.isMachine ? !url : !perfume.kodomizerEan;
    },
    handleClickSmell(perfume) {
      this.startTimer();
      if (!this.backoffice) return;
      if (this.isProfiler && !this.isMachine) {
        const windowReference = window.open("about:blank");
        this.eventTracker.emitEvent("ACTION", {
          type: "BUY",
          data: { perfume: perfume.id },
        });
        if (windowReference) {
          windowReference.location = perfume.productUrl || perfume.productLink;
        }
        return;
        /**
         * Removing this lines because recommendation now send all the info 
        this.loadingIds = {...this.loadingIds,[perfume.id]: true}
        return getPerfumeById(perfume.id, this.backoffice.id, this.lang).then(
          (perfume) => {
            this.loadingIds[perfume.id] = false
            this.eventTracker.emitEvent('ACTION', { type: 'BUY', data: { perfume: perfume.id }});
            if (windowReference) {
              windowReference.location = perfume.productUrl || perfume.productLink
            }
            return
          }
        );
         */
      }
      const selectedEan = perfume.kodomizerEan;
      this.selectedPerfume = perfume;
      if (selectedEan) {
        this.selectedEan = selectedEan;
        this.eventTracker.emitEvent("ACTION", {
          type: "SMELL",
          data: { perfume: perfume.id },
        });
        this.timeout = setTimeout(() => {
          this.selectedEan = null;
        }, 8000);
      }
      // timeout for wait to smelling transition
      setTimeout(() => {
        if (this.$refs.mainRef)
          this.$router.replace({
            ...this.$route,
            query: { ...this.$route.query, selected: perfume.id },
          });
        this.$refs.mainRef.scrollTop = 0;
      }, 300);
    },
    lightOrDark(color) {
      let r, g, b;

      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      let hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      if (hsp > 127.5) {
        return "light";
      } else {
        return "dark";
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.recommendation-one-perfume
  height: 100%
  overflow-y: auto

  .smelling-component
    &.fade-enter-active
      transition: all 0.2s

    &.fade-leave-active
      transition: all 0.3s

    &.fade-enter
      opacity: 0

    &.fade-leave-to
      opacity: 0

  .loading
    left: 50%
    margin-top: vw(-100px)
    position: absolute
    top: 50%
    transform: translate(-50%, -50%)

    > div
      margin: auto
      text-align: center

    .loading-title
      m-font-size(50)
      margin-top: vw(-100px)

      .mobile &
        m-font-size(30, 36)
        margin-bottom: vh(20px)

    .loading-sub-title
      m-font-size(20)

      .mobile &
        m-font-size(14, 22)

    .loading-icon
      background-image: url("~@/assets/loading.gif")
      background-position: center center
      background-repeat: no-repeat
      background-size: cover
      height: vw(650px)
      width: vw(650px)

      .mobile &
        height: vw(450px)
        width: vw(450px)

  .wrapper-header
    background-position: center top
    background-repeat: no-repeat
    background-size: cover
    padding-bottom: vw(175px)
    padding-top: vw(175px)
    position: relative

    .mobile &
      background-size: contain

    .mobile &
      background-size: contain
      padding-bottom: 0
      padding-top: vw(70px)

    &:before
      background: linear-gradient(0, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))
      content: ""
      height: 100%
      left: 0
      position: absolute
      top: 0
      width: 100%

    .restart
      background-color: #000
      color: #fff
      display: flex
      justify-content: center
      padding: vw(30px) vw(15px)
      position: relative
      width: 100%
      z-index: 1

    .restart-text
      m-font-size(12, 14)
      text-align: center
      text-transform: uppercase

      &:after
        background-color: #fff
        content: ""
        display: block
        height: 1px
        margin-top: vw(3px)
        width: 100%

  .header
    align-items: center
    color: #fff
    display: flex
    justify-content: space-between
    left: 0
    padding: vw(60px)
    position: absolute
    top: 0
    width: 100%
    z-index: 10

    .mobile &
      height: vw(70px)
      padding: 0 vw(20px)

    .header-title
      m-font("Paco")
      m-font-size(26, 36)
      flex: 1 1 0%

      .mobile &
        m-font-size(18, 22)

    .header-restart
      m-font("Paco")
      m-font-size(14, 17)
      cursor: pointer
      position: relative
      text-transform: uppercase
      z-index: 1

      .mobile &
        display: none

      &:after
        background-color: #fff
        content: ""
        display: block
        height: 1px
        margin-top: vw(3px)
        width: 100%

      .mobile &
        m-font-size(0)
        m-icon("restart-white", 30)

  .body
    display: flex
    margin-left: auto
    margin-right: auto
    max-width: vw(1120px)
    padding: vw(60px)
    position: relative

    .mobile &
      flex-direction: column
      margin: 0
      padding: vw(10px)

    &.dark
      color: #fff

      .right
        hr
          border-color: rgba(#fff, 0.1)

      .btn
        background-color: #fff
        color: #000

      >>> .dots-wrapper
        .dot
          border-color: #fff

          &.fill
            background-color: #fff

    .left
      flex: 0 0 40%
      max-width: 40%
      padding-right: vw(60px)

      .mobile &
        flex: 0 0 30vh
        max-width: 100%
        padding: vw(16px)
        padding-bottom: 0

      .image
        background-position: center top
        background-repeat: no-repeat
        background-size: contain
        height: vw(400px)

        .mobile &
          height: 100%

    .right
      flex: 0 0 60%

      .mobile &
        flex: 1 1 0%
        padding: vw(16px)

      .header-right
        margin-bottom: vw(40px)

        .mobile &
          margin-bottom: vw(10px)

        .title
          m-font("DIN Engschrift Std")
          m-font-size(45, 50)
          font-style: normal
          margin-bottom: vw(10px)
          text-transform: uppercase

          .mobile &
            m-font-size(35, 40)
            margin-bottom: vw(5px)

        .subtitle
          m-font-size(16, 20)

      hr
        border-color: rgba(#000, 0.1)
        margin: 0

      >>> .intensities
        table
          width: 100%

  .other-perfumes
    padding-bottom: vw(70px)
    padding-top: vw(70px)

    .mobile &
      padding-bottom: vw(40px)
      padding-top: vw(40px)

    .swiper-slide
      margin-right: vw(5px)
      width: 60%

      &:last-child
        margin-right: 0

    .title
      m-font("DIN Engschrift Std")
      m-font-size(45, 50)
      margin-bottom: vw(70px)
      margin-top: 0
      text-align: center
      text-transform: uppercase
      width: 100%

      .mobile &
        m-font-size(28, 30)
        margin-bottom: vw(40px)

    .wrapper
      display: flex
      flex-wrap: wrap

      >>>.perfume-card
        $percentage = percentage((1 / 4))
        $margin = vw(2px)
        $width = "calc(%s - %s)" % ($percentage $margin)
        flex: 0 0 $width
        margin-right: $margin

        &:last-child
          margin-right: 0

  .btn-discover
    padding-bottom: vw(15px)
    padding-top: vw(15px)
</style>